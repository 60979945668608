@use './variables' as v;

.headline-container {
    h1 {
        margin-bottom: v.$paddingDefault;
    }
}

.click-zone {
    border: 1px dashed #d9d9d9;
    border-radius: 32px;
    background: white;
    max-width: 1000px;
    display: grid;
    transition: 0.3s;
    margin: v.$paddingDefault auto auto;

    &.hovering {
        border: 1px dashed black;
        color: #d9d9d9 !important;
    }
}

.click-zone:hover {
    cursor: pointer;
    border: 1px dashed var(--gray950);
}

.drag-drop {
    display: flex;
    justify-self: center;
    align-items: center;
    margin: v.$paddingMiddle * 2 auto;
}

.drag-drop-text {
    margin-left: v.$paddingDefault;
    letter-spacing: 0.4px;
    font-size: 18px;
    font-weight: 600;

    & p {
        margin: v.$paddingMini 0 0;
        font-size: 0.7em;
        font-weight: 500;

        span {
            text-decoration: underline;
            color: var(--gray950);
        }
    }
}

.dropzone {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: none;
    text-align: center;
    color: white;
    align-content: center;
    background-color: var(--gray950);
    &::before {
        content: attr(data-dropzone-label);
        @apply typ-h1;
    }

    &::after {
        content: attr(data-dropzone-description);
        @apply typ-p; // TODO: why???
        opacity: 0.63;
    }
}

.dropzone .dropzone-text {
    height: 120px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    pointer-events: none;
}
.click-zone:hover .add-icon {
    color: #282828;
}
.add-icon {
    color: #141414;
    width: 56px;
    height: 56px;
    transition: 0.3s;
}

.upload-button-container {
    margin-top: v.$paddingLarge;
    text-align: center;
}
