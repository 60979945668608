@use 'variables' as v;
.icon-size-md,
.icon-size-default {
    font-size: 1.25rem;
    svg {
        width: 1rem;
        height: 1rem;
    }
}

.typ-icon,
.icon,
.icon-flat,
.typ-prefix {
    display: grid;
    align-content: center;
    justify-content: center;
    border-radius: 0.5rem;
}

.icon-flat {
    padding: 0.75rem;
    background-color: var(--gray50);
}

.icon-size-xl {
    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.icon-size-2xl {
    svg {
        width: 2rem;
        height: 2rem;
    }
}
.icon-size-3xl {
    svg {
        width: 3rem;
        height: 3rem;
    }
}

.icon-size-lg {
    width: 1.5rem;
    height: 1.5rem;
    svg {
        width: 1.25rem;
        height: 1.25rem;
    }
}

.icon-size-sm {
    width: 1.25rem;
    height: 1.25rem;
    svg {
        width: 0.8125rem;
        height: 0.8125rem;
    }
}

.icon-size-xs {
    svg {
        width: 0.625rem;
        height: 0.625rem;
    }
}

.icon-white {
    &__white {
        svg {
            background: #ffffff;
        }
    }
}
