@use 'theming.variables' as tv;

// https://stackoverflow.com/questions/50055751/how-to-create-toggle-switch-on-off-buttons-in-angular-4-with-ngmodel-two-way
//switch toggle
$toggle-foreground: #f1f1f1;
$toggle-background: #7a7a7a;

@mixin buttonClickIn {
    transition: background-color tv.$hoverTransitionSpeedIn, background tv.$hoverTransitionSpeedIn,
        opacity tv.$hoverTransitionSpeedIn;
}

@mixin buttonClickOut {
    transition: background-color tv.$hoverTransitionSpeedOut, background tv.$hoverTransitionSpeedOut,
        opacity tv.$hoverTransitionSpeedOut, border-color tv.$hoverTransitionSpeedOut;
}

@mixin buttonLayout {
    display: flex;
    gap: 0.25rem;
    position: relative;
    align-content: center;
    align-items: center;
    padding: 0 1rem;
    @include tv.textDefault;
    @include buttonClickOut;

    .typ-button__label,
    .typ-menu-item-label {
        line-height: 1.2;
        flex-grow: 1;
        &:empty {
            display: none;
        }
    }
}

@mixin buttonStyles {
    &__main,
    &__cta,
    &__secondary,
    &__third,
    &__fourth,
    &__text-mode,
    &__upgrade,
    &__premium {
        &:focus {
            box-shadow: none;
            outline: none;
        }

        &:disabled {
            cursor: not-allowed;
            opacity: 0.65;
        }

        &:active {
            @include buttonClickIn;
        }
    }

    &__main,
    &__cta,
    &__secondary,
    &__third,
    &__text-mode,
    &__upgrade,
    &__premium {
        @include buttonClickOut;
        @include tv.textDefault;
    }

    &__main {
        background-color: var(--gray900);
        color: var(--gray50);

        &:hover {
            background-color: var(--gray800);
        }

        &:focus-visible {
            background-color: var(--gray600);
            box-shadow: 0 0 0 2pt var(--gray950);
            text-decoration: underline;
        }

        &:active {
            background-color: var(--gray700);
        }
    }

    &__cta {
        background-color: var(--gray950);
        color: var(--gray50);

        &:hover {
            background-color: var(--gray900);
        }

        &:focus-visible {
            background-color: var(--gray700);
            box-shadow: 0 0 0 2pt var(--gray950);
            text-decoration: underline;
        }

        &:active {
            background-color: var(--gray800);
        }
    }

    &__secondary {
        background-color: tv.$secondary;
        color: tv.$secondaryInside;

        &:hover {
            background-color: tv.$secondaryHover;
        }

        &:focus-visible {
            background-color: tv.$secondaryFocused;
            box-shadow: 0 0 0 2pt var(--gray950);
            text-decoration: underline;
        }

        &:active {
            background-color: tv.$secondaryActive;
        }
    }

    &__third {
        background-color: var(--gray50);
        color: var(--gray950);

        &:hover {
            background-color: var(--gray100);
        }

        &:focus-visible {
            background-color: var(--gray200);
            box-shadow: 0 0 0 2pt var(--gray950);
            text-decoration: underline;
        }

        &:active {
            background-color: var(--gray100);
        }
    }

    &__fourth {
        background-color: transparent;
        color: var(--gray600);
        @include buttonClickOut;
        text-decoration: underline;
        @include tv.textSmall;

        &:hover {
            color: var(--gray700);
        }

        &:focus-visible {
            color: var(--gray900);
            box-shadow: 0 0 0 2pt var(--gray950);
            text-decoration: underline;
        }

        &:active {
            color: var(--gray800);
        }
    }

    &__text-mode {
        background-color: tv.$textMode;
        color: tv.$textModeInside;

        &:hover {
            background-color: tv.$textModeHover;
        }

        &:focus-visible {
            background-color: tv.$textModeFocused;
            box-shadow: 0 0 0 2pt var(--gray950);
            text-decoration: underline;
        }

        &:active {
            background-color: tv.$textModeActive;
        }
    }
    &__upgrade,
    &__premium {
        background: tv.$gradientUpgrade !important; // TODO: remove !Important with the refactoring of button component
        transition: tv.$transformTransitionSpeedIn;
        color: #fff;
        // NOTE: background gradient transition support is really bad. Thats why we are using opacity
        &:hover {
            opacity: 0.9;
        }

        &:focus-visible {
            opacity: 0.9;
            box-shadow: 0 0 0 2pt var(--gray950);
            text-decoration: underline;
        }

        &:active {
            opacity: 0.9;
        }
    }
}
