/*
in this scss all word-styling stuff is stored:
the word styling for
 -> textEditor, share.component,...

 Everything where we need to highlight words

*/

$textModeHighlight: var(--green100);
$cuttedWord: var(--gray400);
$linebreakSize: 24px;
