@use 'variables' as v;

.grid-content {
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    grid-gap: 1rem;
}

.full-width {
    width: 100%;
}

.typ-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider-x {
    width: 100%;
    position: relative;

    &:before {
        position: absolute;
        width: calc(100% - #{v.$paddingMiddle * 1.5});
        height: 1px;
        background: var(--gray25);
        top: -0.5px;
        left: v.$paddingMiddle * (2/3);
        content: '';
    }
}

.typ-border-wrap {
    background: var(--gray100);
    border: 2px solid var(--gray300);
    box-sizing: border-box;
    border-radius: 1.5rem;
    padding: 1rem;
}

@media (min-width: 640px) {
    .typ-border-wrap {
        padding: 2rem;
    }
}
