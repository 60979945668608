@use 'variables' as v;

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// START: Undo tailwind changes to these elements
html {
    line-height: 1;
    overscroll-behavior: none;
}

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

*:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

@layer base {
    h1,
    h2,
    h3,
    h4,
    h5 {
        font-weight: bold;
    }
    h1 {
        @apply text-3xl;
        margin: 0.67em 0;
    }
    h2 {
        @apply text-2xl;
    }
    h3 {
        @apply text-xl;
    }
}

@import 'resets';
// END: Undo tailwind changes to these elements

@import '@angular/cdk/overlay-prebuilt.css';

@import 'badge';
@import 'button';
@import 'card';
@import 'dialog';
@import 'drag-n-drop';
@import 'error-modal';
@import 'form';
@import 'icon';
@import 'key';
@import 'layout';
@import 'loader';
@import 'menu';
@import 'premium';
@import 'scroll';
@import 'text';
@import 'upload';
@import 'separator';
@import 'background';
@import 'border';
@import 'shadow';
@import 'tile';
@import 'text-editor';

* {
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
}

body {
    min-height: 100vh;
    min-width: 100vw;
    background-color: var(--gray50);
    margin: 0;
    overscroll-behavior-y: none;
}

@media screen and (max-width: 640px) {
    html,
    body {
        overflow-x: hidden;

        position: fixed;
    }
}

a {
    text-decoration: none;
    cursor: pointer;

    @include v.user-select;
}

p {
    line-height: 1.7;
    opacity: v.$opacitySmall;
    font-size: 14px;
}

.container {
    width: 100%;
    max-width: unset;
}

h1,
h2,
h3,
h4,
app-toast p {
    opacity: v.$opacityDefault;
}

app-toast p {
    padding: v.$paddingSmall;
}

.konvajs-content canvas {
    @include v.videoFrame;
}

.konvajs-content {
    margin: 0 auto;
}

// Angular Material styling
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
.mat-accent .mat-slider-track-fill {
    background-color: var(--gray950) !important;
}
.mat-accent .mat-slider-track-background {
    background-color: var(--gray100) !important;
}

.mat-slider-thumb {
    background-color: var(--gray950) !important;
}
