/* You can add global styles to this file, and also import other style files */

:root {
    --fullBlack: theme('colors.r.black');
    --fullWhite: theme('colors.r.white');

    //gray
    --gray25: theme('colors.r.gray.25');
    --gray50: theme('colors.r.gray.50');
    --gray100: theme('colors.r.gray.100');
    --gray200: theme('colors.r.gray.200');
    --gray300: theme('colors.r.gray.300');
    --gray400: theme('colors.r.gray.400');
    --gray500: theme('colors.r.gray.500');
    --gray600: theme('colors.r.gray.600');
    --gray700: theme('colors.r.gray.700');
    --gray800: theme('colors.r.gray.800');
    --gray900: theme('colors.r.gray.900');
    --gray950: theme('colors.r.gray.950');

    //error
    --red100: theme('colors.r.red.100');
    --red500: theme('colors.r.red.500');
    
    //success
    --green100: theme('colors.r.green.100');
    --green400: theme('colors.r.green.400');
    
    //record button
    --red600: theme('colors.r.red.600');

    //text correction
    --green200: theme('colors.r.green.200');
}

// // To be done
// @media (prefers-color-scheme: dark) {
//     :root {
//         --fullBlack: theme('colors.r.white');
//         --fullWhite: theme('colors.r.black');
//         --gray25: theme('colors.r.gray.950');
//         --gray50: theme('colors.r.gray.950');
//         --gray100: theme('colors.r.gray.900');
//         --gray200: theme('colors.r.gray.800');
//         --gray900: theme('colors.r.gray.100');
//         --gray950: theme('colors.r.gray.50');
//     }
// }

//START REFACTORING
//MAIN COLORS
// Todo: Refactor all colors and use same names as in Figma.
$black: #2b2a35; //gray950
$black05: #f6f6f9; //gray50
$black1: #eeeff5; //gray100
$black2: #d4d5d9; //gray200
$black3: #c0c0c7; //gray300
$black8: #55555d; //gray800
$hrt: #f1f2f8;

$textColor: rgb(17, 17, 17);
$text-color-default: #2b2b2b;
$text-color-light: #858578;
$color-success-light: #c3edb4;

$main: #2d3450; // var(--gray900)
$mainHover: #39405a; // var(--gray800)
$mainFocused: #474d65; // var(--gray600)
$mainActive: #424861; // var(--gray700)
$mainInside: #f2f3f4; // var(--gray50)

$cta: #141414; // var(--gray950)
$ctaHover: #282828; // var(--gray900)
$ctaFocused: #5e5e5e; // var(--gray700)
$ctaActive: #4b4b4b; // var(--gray800)
$ctaActiveAccent: #e8e8e8; // var(--gray100)
$ctaActiveSubHeadline: #a6a6a6; // var(--gray400)
$ctaInside: #f3f3f3; // var(--gray50)

$secondary: #fff; // var(--fullWhite)
$secondaryHover: #eceef5; // var(--gray50)
$secondaryFocused: #d8dae0; // var(--gray200)
$secondaryActive: #e2e4eb; // var(--gray100)
$secondaryInside: #2b2b2b; // var(--gray900)
// $secondaryInside: red;

$third: #f3f4f9; // var(--gray25)
$thirdHover: #e9eaef; // var(--gray50)
$thirdFocused: #d6d7db; // var(--gray200)
$thirdActive: #e0e0e5; // var(--gray100)
$thirdInside: #57585a; // var(--gray700)
// $thirdInside: green;

$fourth: #757575; // var(--gray600)
$fourthHover: #646464; // var(--gray700)
$fourthFocused: #595959; // var(--gray900)
$fourthActive: #626262; // var(--gray800)
// $fourthInside: #57585A;
// $thirdInside: green;

$rareUserWhiteHover: #f9fafc;
$rareUserWhiteFocus: #f9f9fc;

//Special Colors

$errorColor: #e75051;
$errorBg: #fadcdc;

$textMode: #c3edb4;
$textModeHover: #bbe4ad;
$textModeFocused: #abd09e;
$textModeActive: #b4daa6;
$textModeInside: #465641;

$textSelected: #b3d7fe;
$searchColor: #d1e9ff;

// Speaker colors
$speakerColors: #c4c4c4, #ff9bad, #fdc59d, #c8b3ff, #80d5ff, #b2f09f, #ec91c9, #80e8d9;

$renderingInProgress: #72727280;
$renderingInProgressHover: #727272;

$renderingCompleted: #2fad7a;
$renderingCompletedHover: rgba(47, 173, 122, 0.94);

$readyToEdit: #52525290;
$readyToEditHover: #525252;

$renderingError: #ff5a5a80;
$renderingErrorHover: #d45f5f;

$premiumBg: #dde2ff;

$successColor: #189f53;
$successBg: #d1ecdd;

$backgroundColor: #fff;

$disabledButtonTextColor: #ababab;

$recordButton: #ff5050;
$recordButtonHover: darken(#ff5050, 10%);

$permissionAccessColorList: #ff5050, #bbe4ad, $cta;

$gradientUpgrade: linear-gradient(
    123.53deg,
    #2de8b0 25.56%,
    #cbe953 60.27%,
    #ffab48 79.52%,
    #ff5151 96.69%
); //Unify in refactoring
$gradientUpgradeLight: linear-gradient(123.53deg, #e0fcf3 25.56%, #fafdee 60.27%, #fffbf6 79.52%, #ffffff 96.69%);
$gradientUltra: linear-gradient(123.53deg, #2de8b0 25.56%, #cbe953 60.27%, #ffab48 79.52%, #ff5151 96.69%);
$gradientUltraLight: linear-gradient(123.53deg, #e0fcf3 25.56%, #fafdee 60.27%, #fffbf6 79.52%, #ffffff 96.69%);

// Plan colors
$planTrialAccent: $black1;
$planStarterAccent: #ffd63d;
$planProAccent: #5ee57c;
$planBusinessAccent: black;

//Border Radius
$borderRadiusMini: 4px;
$borderRadiusSmall: 8px;
$borderRadiusDefault: 16px;
$borderRadiusBig: 24px;

//Padding

$paddingMini: 4px;
$paddingSmall: 8px;
$paddingDefault: 16px;
$paddingMiddle: 24px;
$paddingLarge: 32px;
$paddingButton: 14px 24px;

//Transitions:

$hoverTransitionSpeedIn: 0.1s;
$hoverTransitionSpeedOut: 0.4s;

$transformTransitionSpeedIn: 0.2s;
$transformTransitionSpeedOut: 0.2s;

//DEFAULT TEXT
// TODO: Maybe remove this?
$opacityDefault: 1;
$opacitySmall: 0.6;

//CustomVariables

// To get inner editor wrapper height
$topMenuBarHeight: 72px;
$topPaddingInnerWrapper: 16px;
$bottomPaddingInnerWrapper: 16px;
$topMarginInnerWrapper: 12px;
$bottomMarginInnerWrapper: 12px;

//Mixins

@mixin textBig {
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.1px;
}

@mixin textDefault {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.006em;
}

@mixin textDense {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
}

@mixin textSmall {
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.1px;
}

@mixin truncateText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

//boxes

@mixin boxSmall {
    border-radius: $borderRadiusSmall;
    padding: $paddingSmall;
}

@mixin boxDefault {
    border-radius: $borderRadiusDefault;
    padding: $paddingDefault;
}

@mixin boxMiddle {
    border-radius: $borderRadiusDefault;
    padding: $paddingMiddle;
}

@mixin boxLarge {
    border-radius: $borderRadiusDefault;
    padding: $paddingLarge;
}

@mixin borderStyleDefault {
    border: 2px solid;
}

@mixin borderStyleThin {
    border: 1px solid $thirdHover;
    border-radius: $borderRadiusSmall;
}

//selectables

@mixin user-select {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@mixin selectable-text {
    -moz-user-select: text;
    -khtml-user-select: text;
    -webkit-user-select: text;
    -ms-user-select: text;
    user-select: text;
}

//shadows

@mixin shadowFloating {
    box-shadow: 1px 0 5px rgba(0, 0, 0, 0.06), 0 -2px 20px rgba(0, 0, 0, 0.03),
        0 18.2136px 14.5708px rgba(0, 0, 0, 0.035), 0px 9.67308px 7.73847px rgba(0, 0, 0, 0.0282725),
        0 4.02519px 3.22015px rgba(0, 0, 0, 0.0196802);
}

// END REFACTORING

//text-in
//text-input
@mixin text-input {
    .edit-input {
        box-shadow: 0 0 0 2px var(--gray50);
        border-radius: 12px;
        height: 32px;
        margin: -6px;
        margin-left: -8px;
        padding: 0 18px 0 6px;
        font-size: 16px;
        border: 2px solid white;
        transition: $hoverTransitionSpeedIn;
    }

    .edit-input:focus {
        border-radius: 12px;
        border: 2px solid #141414;
        outline: none;
    }

    .save-changes {
        margin-right: 8px;
        margin-left: -18px;
    }

    .save-changes img {
        padding-bottom: 2px;
    }
}

//MOBILE SPECS//

@mixin fix-browsersearch {
    height: 100%;
    position: fixed;
    /* Allows you to scroll below the viewport; default value is visible */
    overflow-y: scroll;

    /* To smooth any scrolling behavior */
    -webkit-overflow-scrolling: touch;
}

@mixin videoFrame {
    box-shadow: 0 0 0 2px $thirdFocused;
    border-radius: $borderRadiusSmall;
    background-color: $thirdHover;
}

//SL Login/SignUp font
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@500&display=swap');
