@use './variables' as v;

.premium-feature {
    background: v.$gradientUpgradeLight;
    border-radius: 16px;
    margin-top: 16px;
    font-size: 14px;
}

.premium-tag {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    & span {
        margin-left: 8px;
        font-weight: 600;
        color: var(--gray950);
    }

    & img {
        vertical-align: bottom;
    }
}

.premium-text {
    background-image: v.$gradientUpgrade;
    background-size: 100%;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.premium-background-light {
    background: v.$gradientUpgradeLight;
}
