@use 'variables' as v;

// Overwrite tailwind forms
.form-input,
.form-select {
    border-color: rgba(0, 0, 0, 0.31);
    border-width: 1px;
    border-radius: 0.5rem;
    height: 3rem;
    font-size: 14px;
    background-color: var(--gray50);
    color: #57585a;
    font-weight: 500;
}

.typ-form-field {
    display: flex;
    padding: 0 0.25rem;
    border: 1px solid var(--gray300);
    border-radius: 0.5rem;
    background-color: var(--gray25);
    color: var(--gray800);

    &:focus-within {
        background-color: var(--gray50);

        border-color: var(--gray950);
        color: var(--gray700);
    }
    &:hover {
        background-color: var(--gray50);
    }

    .typ-prefix,
    .typ-suffix {
        flex-grow: 0;
        flex-shrink: 0;
        padding: 0 0.75rem;
        svg {
            width: 1.5rem;
            height: 1.5rem;
        }
    }

    .typ-input {
        @include v.textDefault;
        flex-grow: 1;
        flex-shrink: 1;
        height: 3rem;
        outline: none;
        background-color: transparent;
        &:focus {
            box-shadow: none;
            outline: none;
        }

        ::-ms-input-placeholder,
        :-ms-input-placeholder,
        ::placeholder {
            color: var(--gray700);
            opacity: 0.3;
        }
    }
}

//default
.default-toggle {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 28px;

    &__disabled {
        opacity: 0.3;
    }

    input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .default-toggle__slider {
            background-color: var(--gray950);
        }

        &:focus + .default-toggle__slider {
            box-shadow: 0 0 1px var(--gray950);
        }

        &:checked + .default-toggle__slider:before {
            transform: translateX(24px);
        }
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 0.4s;
        border-radius: 34px;
        background-color: var(--gray200);

        &:before {
            position: absolute;
            content: '';
            height: 24px;
            width: 24px;
            left: 2px;
            bottom: 2px;
            background-color: white;
            transition: 0.4s;
            border-radius: 50%;
        }
    }

    &.middle {
        width: 40px;
        height: 24px;

        input {
            &:checked + .default-toggle__slider:before {
                transform: translateX(17px);
            }
            &:focus + .default-toggle__slider {
                box-shadow: none;
            }
        }

        .default-toggle__slider {
            &:before {
                height: 20px;
                width: 20px;
            }
        }
    }

    &.small {
        width: 28px;
        height: 16px;

        input {
            &:checked + .default-toggle__slider:before {
                transform: translateX(12px);
            }
        }

        .default-toggle__slider {
            &:before {
                height: 12px;
                width: 12px;
            }
        }
    }
}

.default-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    color: var(--gray950);
    @include v.textDefault;
    @include v.user-select;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .default-checkbox__checkmark {
            background-color: var(--gray950);
            border-color: var(--gray800);
        }

        &:checked ~ .default-checkbox__checkmark:after {
            display: block;
            background-image: url('/assets/ic/check.svg');
        }
    }

    &:hover input ~ .default-checkbox__checkmark {
        background-color: var(--gray50);
        border-color: var(--gray50);
    }

    &__checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: var(--gray25);
        border-radius: v.$borderRadiusSmall;
        border: 1px solid var(--gray25);

        &:after {
            content: '';
            position: absolute;
            display: none;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            filter: invert(1);
            width: 16px;
            height: 16px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.default-input {
    display: grid;
    column-gap: 8px;
    text-align: left;

    &__label {
        @include v.textDefault;
        color: var(--gray700);
        margin-left: v.$paddingMini;
        margin-bottom: v.$paddingMini;
        opacity: v.$opacitySmall;
    }

    &__message {
        @include v.textSmall;
        margin: 0 0 0 v.$paddingMini;

        &--error {
            color: var(--red500);
        }

        &--success {
            color: v.$successColor;
        }
    }

    &__input-field {
        $imageWidth: 18px;
        position: relative;

        // Note: in HTML place both images before the input element
        &__image-before,
        &__image-after {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
        }

        &__image-before {
            left: v.$paddingDefault;

            & ~ input {
                padding-left: calc(#{v.$paddingDefault * 2 + $imageWidth} - 4px) !important;
            }
        }

        &__image-after {
            right: v.$paddingDefault;

            & ~ input {
                padding-right: v.$paddingDefault * 2 + $imageWidth;
            }
        }

        &__input {
            display: block;
            width: 100%;
            height: 48px;
            padding: 0 v.$paddingDefault;
            @include v.textDefault;
            color: var(--gray700);
            @include v.borderStyleThin;
            @include v.buttonClickOut;
            outline: none;
            background-color: #fbfcfc;

            &:hover {
                background-color: var(--gray50);
            }

            &:focus {
                box-shadow: none;
                outline: none;
            }

            &:focus-visible {
                background-color: var(--gray50);
                @include v.borderStyleDefault;
                border-color: var(--gray950);
                padding-left: 15px;
                color: var(--gray700);
            }

            &--error {
                @include v.borderStyleDefault;
                padding-left: 15px;
                border-color: var(--red500);
            }
            &__text-area {
                min-height: 200px;
                max-height: 400px;
                line-height: 18.5px;
                padding: v.$paddingDefault;
                resize: vertical;
                &:focus-visible {
                    padding-top: 15px;
                }
            }
        }
    }

    ::placeholder {
        color: var(--gray700);
        opacity: 0.3;
        /* Firefox */
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: var(--gray700);
        opacity: 0.3;
    }

    ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: var(--gray700);
        opacity: 0.3;
    }
}

.default-toast__text {
    @include v.textDefault;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--gray50) inset !important;
    -webkit-text-fill-color: var(--gray700) !important;
}

input:focus,
input:focus-visible {
    outline: none;
}

input {
    border-bottom: 1px solid transparent;
    transition: border-bottom-color ease-in-out 150ms;
}

.typ-input-inline:hover {
    border-bottom: 1px solid var(--gray300);
}
.typ-input-inline:focus-within {
    border-bottom: 1px solid var(--gray950);
}
.typ-input-inline.typ-input-invalid {
    border-bottom-color: var(--red500);
}
