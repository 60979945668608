@use 'variables' as v;

.typ-menu-item {
    @include v.buttonLayout;
    width: 100%;
    width: stretch;
    min-height: 2.5rem;
    background-color: transparent;
    border-radius: v.$borderRadiusSmall;
    overflow: hidden;
    cursor: pointer;
}
.menu-item-separator {
    position: relative;
    width: calc(100% - #{v.$paddingSmall * 2});
    height: 1px;
    background: var(--gray100);
    margin: v.$paddingSmall;
}

.typ-menu-search {
    border-bottom: 1px solid var(--gray25);
    input {
        caret-color: var(--gray950);
        border: none;
        background: none;
        height: unset;
        padding-left: v.$paddingMini;
    }
}

.typ-menu-item {
    &:hover,
    &:focus {
        background-color: var(--gray50);
    }

    &:focus {
        box-shadow: none;
        outline: none;
    }

    &:focus-visible {
        background-color: var(--gray200);
        box-shadow: 0 0 0 2pt var(--gray950);
        text-decoration: none;
    }

    &:active {
        @include v.buttonClickIn;
        background-color: var(--gray100);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.65;
    }

    &.selected {
        background: var(--gray100);
        color: var(--gray950);
        .typ-menu-item-sub-header {
            color: var(--gray400);
        }
    }
}

.typ-menu-item-label {
    text-align: start;

    &:empty {
        display: none;
    }
}

.typ-menu-item-header,
.typ-menu-item-sub-header {
    display: block;
    width: 100%;
    line-height: 139%;
}

.typ-menu-item-header {
    font-weight: 500;
    font-size: 14px;
    padding-top: 0.5rem;
}

.typ-menu-item-sub-header {
    color: var(--gray500);
    font-weight: 400;
    font-style: normal;
    font-size: 10px;
    padding-bottom: 0.5rem;
}

/* TAB MENU */
.tab-menu-title {
    order: 1;
    span {
        border-bottom: 2px solid transparent;
        transition: all v.$hoverTransitionSpeedIn;
    }
    &.selected {
        span {
            color: var(--gray950);
            border-bottom-color: var(--gray950);
        }
    }
}
.tab-menu-content {
    order: 2;
    flex: 1 100%;
    margin-top: 12px;
}
