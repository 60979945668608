.scroll-container,
.scroll-container--y,
.scroll-container--y-auto,
.scroll-container--x {
    overflow: scroll;
    scroll-behavior: smooth;
    --scroll-width: 8px;

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar {
        background-color: var(--gray50);
    }

    &::-webkit-scrollbar {
        width: var(--scroll-width);
    }

    &::-webkit-scrollbar-thumb {
        background-color: #dbdee9;

        &:hover {
            background-color: #b8bee1;
        }
    }
}

.scroll-container--y {
    overflow-y: scroll;
    overflow-x: hidden;
}

.scroll-container--y-auto {
    overflow-y: auto;
    overflow-x: hidden;
}

.scroll-container--x {
    overflow-x: scroll;
    overflow-y: hidden;
}

.scroll-bar-hidden::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scroll-bar-hidden {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
