img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: initial;
    vertical-align: initial;
}

input,
button,
optgroup,
select,
textarea {
    line-height: initial;
}

[hidden] {
    display: none;
}
