@use 'variables' as v;

.separator {
    display: grid;
    grid-template-columns: 1fr 40px 1fr;
    width: 100%;

    opacity: v.$opacitySmall;
    margin: v.$paddingMiddle auto v.$paddingDefault;
    align-items: center;
    text-align: center;

    &__line {
        background-color: var(--gray25);
        height: 2px;
        width: 100%;
    }

    &__or {
        @include v.textDefault;
    }
}
