@use './variables' as v;

.skeleton {
    background-repeat: no-repeat;
    background-color: #dddddd;
    border-radius: v.$borderRadiusSmall;
    position: relative;
    overflow: hidden;
    height: 400px;
    max-height: 100%;
    width: 100%;
    max-width: 711px;
    margin: 0 auto;

    &__audio {
        // TODO: this can probably be moved to the actual component
        height: 55px;
    }
}

.skeleton::before {
    content: '';
    display: block;
    position: absolute;
    left: -150px;
    top: 0;
    height: 100%;
    width: 150px;
    background: linear-gradient(to right, transparent 0%, #e8e8e8 50%, transparent 100%);
    animation: load 1s cubic-bezier(0.4, 0, 0.2, 1) infinite;
}

@keyframes load {
    from {
        left: -150px;
    }
    to {
        left: 100%;
    }
}
