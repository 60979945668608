@use './variables' as v;

button {
    border: none;
    list-style: none outside none;
    margin: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
}

.button {
    border: none;
    border-radius: v.$borderRadiusDefault;
    padding: 14px 24px;
    align-items: center;

    @include v.user-select;
    @include v.buttonStyles;
}

.auth-provider-button {
    display: flex;
    justify-content: center;
    height: 48px;
    @include v.borderStyleThin;
    // .auth-logo-icon {
    //     display: contents;
    //     svg {
    //         width: 2rem;
    //         height: 2rem;
    //     }
    // }
}

.auth-logo-icon {
    display: contents;
    padding: 0;
    svg {
        width: 1.5rem;
        height: 1.5rem;
    }
}

.typ-inline-button {
    color: var(--gray950);
    text-decoration: underline;
    font-weight: inherit;
}

.typ-button-upgrade-hover-bg {
    --bg-opacity: 0;
    &::before {
        content: ' ';
        transition: opacity ease-in-out v.$transformTransitionSpeedIn;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        opacity: var(--bg-opacity);
        background: v.$gradientUltraLight padding-box, v.$gradientUltra border-box;
        border: 2px solid transparent;
    }
    &:hover {
        --bg-opacity: 0.25;
    }
}

.button-small {
    cursor: pointer;
    align-items: center;
    @include v.user-select;
    border: none;
    border-radius: v.$borderRadiusSmall;
    @include v.buttonStyles;
}
