@use 'variables' as v;

.typ-tile {
    border-radius: 18px; // v.$borderRadiusDefault;
    overflow: hidden;
    -webkit-transform: translate3d(0, 0, 0); // Hack to ensure overflow is hidden on Safari
    width: 100%;
    position: relative;
    display: grid;
    grid-template: 'container';
    place-items: stretch;
    place-content: stretch;
    text-align: left;
    &:focus-visible {
        box-shadow: 0 0 0 2pt var(--gray950);
    }

    &:hover,
    &.typ-tile-active {
        .typ-tile-visible-when-active {
            opacity: 1;
            max-height: 100px;
            min-height: 2rem;
        }
    }
}

@media (min-width: 640px) {
    .typ-tile-visible-when-active {
        overflow: hidden;
        transition: all v.$hoverTransitionSpeedIn ease-in-out;
        opacity: 0;
        max-height: 0;
        min-height: 0;
    }
}

.typ-tile-bg {
    grid-area: container;
    object-fit: cover;
    user-select: none;
    background-position: center;
    background-size: cover;
    transition: transform ease-in-out v.$hoverTransitionSpeedIn;
    //aspect-ratio: 16 / 9;
}

///* aspect-ratio fallback */
//@supports not (aspect-ratio: 16 / 9) {
//    .typ-tile-bg {
//        &::before {
//            content: '';
//            float: left;
//            padding-top: 56.25%;
//        }
//
//        &::after {
//            content: '';
//            display: block;
//            clear: both;
//        }
//    }
//}

.typ-tile-content {
    grid-area: container;
    align-self: end;
    display: grid;
    align-items: end;
    align-content: end;
    justify-items: start;
    padding: 1.5rem 1rem 1rem 1rem;
    background-size: 200% 180%;
    //background-image: linear-gradient(0deg, #000000ce 0, #79797900 100%);
    transform: scale(1); // This is a hack to keep this content above the bg-image
}

.typ-tile-actions {
    display: flex;
    width: 100%;
}

.typ-tile-spacer {
    display: block;
    height: 0.5rem;
    min-height: 0 !important;
}

.typ-tile-sub-header {
    height: 1.25rem;
    &:empty {
        height: 0;
    }
}

.typ-tile-aspect-ratio {
    aspect-ratio: 16 / 9;
}

/* aspect-ratio fallback */
@supports not (aspect-ratio: 16 / 9) {
    .typ-tile-aspect-ratio {
        &::before {
            content: '';
            float: left;
            padding-top: 56.25%;
        }

        &::after {
            content: '';
            display: block;
            clear: both;
        }
    }
}
