@use 'variables' as v;

.border-radius-lg {
    border-radius: v.$borderRadiusDefault;
}

.scroll-shadow-vertical {
    background: linear-gradient(white 30%, hsla(0, 0%, 100%, 0)),
        linear-gradient(hsla(0, 0%, 100%, 0) 10px, white 70%) bottom,
        radial-gradient(at top, rgba(0, 0, 0, 0.2), transparent 70%),
        radial-gradient(at bottom, rgba(0, 0, 0, 0.2), transparent 70%) bottom;
    background-repeat: no-repeat;
    background-size: 100% 20px, 100% 20px, 100% 10px, 100% 10px;
    background-attachment: local, local, scroll, scroll;

    //position: relative;
    overflow: auto;
    //background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
    //linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
    //linear-gradient(rgba(34, 34, 34, 0.125) 20%, rgba(0, 0, 0, 0)),
    //linear-gradient(rgba(0, 0, 0, 0), rgba(34, 34, 34, 0.06125) 80%) 0 100%;
    //background-color: #ffffff;
    //background-repeat: no-repeat;
    //background-attachment: local, local, scroll, scroll;
    //background-size: 100% 16px, 100% 16px, 100% 0.35rem, 100% 0.25rem;
}
