@use 'variables' as v;

.cdk-global-overlay-wrapper {
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cdk-overlay-pane > ng-component {
    display: contents;
}

.dialog-container {
    --close-button-offset: 1.5rem;
    position: relative;
    padding: 1.5rem;
    max-width: 100vw;
    width: 100%;
    overflow: auto;
    max-height: 98vh;

    &--close-btn,
    &--close-btn.typ-button-secondary,
    &--back-btn,
    &--back-btn.typ-button-secondary {
        position: absolute;
        top: calc(#{v.$paddingMiddle} + 1rem);
        transform: translateY(-50%);
    }

    &--close-btn,
    &--close-btn.typ-button-secondary {
        right: v.$paddingMiddle;

        // Depending on if the dialog opens a template or a real component.
        + h2:first-of-type,
        + * > h2:first-of-type {
            margin-right: 3rem;
            text-align: left;
        }
    }

    &--back-btn,
    &--back-btn.typ-button-secondary {
        left: v.$paddingMiddle;
    }
}

.full-page-dialog > * {
    width: 100%;
}
.dialog-no-padding .dialog-container {
    padding: 0;
}

.context-menu {
    @include v.shadowFloating;
    @apply bg-white flex p-2 rounded-2xl;
}
