@use 'variables' as v;

$shadowHeight: 3px;
.keys {
    display: flex;
    align-items: center;
    > * {
        margin: 0 v.$paddingMini;
    }
}
.key {
    color: var(--gray900);
    text-transform: lowercase;
    background: var(--gray25);
    border: 1px solid var(--gray50);
    box-sizing: border-box;
    box-shadow: 0 $shadowHeight 0 #dadbe2;
    padding: v.$paddingMini v.$paddingSmall;
    border-radius: v.$borderRadiusSmall;
    display: inline-flex;
    justify-content: center;
    margin: 0 v.$paddingMini $shadowHeight v.$paddingMini;
    min-width: 3ch;
    line-height: 130%;
}
