.typ-drop-zone-mask {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.75);
    transition: opacity 300ms ease-in-out;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 101;
}

.typ-drop-zone-highlight,
.typ-drop-zone-drag-item-clone {
    z-index: 102;
}

.typ-drop-zone-drag-item-clone {
    position: absolute;
    top: -1000px;
    width: 250px;
}

.typ-drop-zone-highlight.typ-drop-zone-drag-item,
.typ-drop-zone-drag-item {
    z-index: 0;
}

.typ-drop-zone-active {
    position: relative;
}
