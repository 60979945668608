/*
in this scss all word-styling stuff is stored:
the word styling for
 -> textEditor, share.component,...

 Everything where we need to highlight words

*/

@use './variables' as v;
$color: blue;
.linebreak {
    height: v.$linebreakSize;
    width: 40px;
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0;
    cursor: pointer;
    transition: opacity v.$hoverTransitionSpeedIn;

    &__icon {
        background-image: url(../assets/ic/24/linebreak.svg);
    }

    &:hover {
        opacity: 1;
    }
}

// Edit word start
.edit-input {
    padding-right: 1px;
    padding-top: 5px;
    margin-top: -5px;
    padding-bottom: 5px;
    margin-bottom: -5px;

    border: none;
    font-size: 16px;
    background: v.$textModeHighlight;
    color: #343f2a;
}

.edit-input:focus {
    outline: none;
}

.save-changes {
    margin-right: 8px;
    margin-left: -18px;
}

.save-changes img {
    padding-bottom: 2px;
}

::selection {
    background: v.$textSelected;
    /* WebKit/Blink Browsers */
}

::-moz-selection {
    background: v.$textSelected;
    /* Gecko Browsers */
}

span {
    line-height: 1.5;
    padding: 0 0;
    // Edit word end
}

p {
    &.text--light,
    &.text,
    &.text__label {
        margin: 0.5rem 0;
    }
}

.text,
.text--light,
.text__label {
    //color: rgba(0, 0, 0, 0.61);
    opacity: 1;
    color: var(--gray700);
}

.text--light {
    color: rgba(0, 0, 0, 0.45);
}

.text__label {
    font-weight: 500;
}

.text__cta {
    color: var(--gray950);
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.loading-ellipsis:after {
    position: absolute;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 1600ms infinite;
    content: '\2026'; /* ascii code for the ellipsis character */
    width: 0;
    --ellipsis-width: 1rem;
}

h1.loading-ellipsis:after {
    --ellipsis-width: 2rem;
}

button.loading-ellipsis:after {
    --ellipsis-width: 0.875rem;
}

.text-no-overflow {
    display: block;
    max-width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

@keyframes ellipsis {
    to {
        width: var(--ellipsis-width);
    }
}

@-webkit-keyframes ellipsis {
    to {
        width: var(--ellipsis-width);
    }
}

// typography
.typ-p,
.typ-h1,
.typ-h2,
.typ-h3,
.typ-h4 {
    font-family: Inter, system-ui;
    font-style: normal;
}

.typ-h1 {
    font-size: 1.875rem;
    line-height: 2.25rem;
    margin: 0.67em 0;
    font-weight: bold;
}

.typ-h2 {
    color: var(--gray950);
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 29px;
    letter-spacing: -0.019em;
}

.typ-h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: -0.014em;
}

.typ-h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
}

.typ-text-400 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.006em;
}

.typ-p,
.typ-ul {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: -0.006em;
    opacity: 1; // TODO: remove this when we remove the base style for p tags
}

.typ-text-small {
    font-size: 12px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.006em;
}

.typ-caption {
    color: var(--gray200);
    font-size: 11px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: -0.006em;
}

// text colors

.color-secondary {
    color: var(--gray25);
}

.color-foreground,
.typ-color-black3 {
    color: var(--gray300);
}

.typ-color-black3 {
    color: var(--gray300);
}

.typ-color-black8,
.color-foreground-dark {
    color: var(--gray800);
}

.typ-color-black {
    color: var(--gray950);
}

$warn: rgba(255, 80, 80, 1);

.text-warn,
.hover\:text-warn:hover {
    color: $warn;

    svg-icon {
        &.color-match,
        &.color-match-stroke {
            svg {
                stroke: $warn;
            }
        }
        &.color-match,
        &.color-match-fill {
            svg {
                fill: $warn;
            }
        }
    }
}

.typ-ul {
    list-style: initial;
    margin: initial;
    padding-inline-start: 1.5rem;
}
