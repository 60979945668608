@use 'variables' as v;
.bg-white {
    background-color: var(--fullWhite);
}

.bg-backdrop {
    background-color: rgba(107, 114, 138, 0.8);
}
.bg-transparent {
    background-color: transparent;
}

.bg-success-light {
    background-color: var(--green100);
}
