@use 'variables' as v;

$space-size: 4.34375px;

.text-editor-content {
    margin-right: -$space-size;
}

.words-container {
    display: grid;
    grid-template-areas: 'content';
}
.custom-text-preview,
.text-editor-content {
    grid-area: content;
}
.custom-text-preview {
    line-height: 1.5;
    white-space: pre-line;
    z-index: -1;
    opacity: 0.6;
}

.text-editor-word {
    display: inline-block;
    .start-marker + .word-real {
        padding-left: $space-size;
    }

    .end-marker:last-child {
        padding-right: 0.375rem;
    }

    &.highlighted {
        background-color: var(--gray50) !important;
        > .start-marker,
        .end-marker {
            & > .marker-icon,
            & > .marker {
                border-color: var(--gray50) !important;
            }
        }
    }

    &.searchHighlighted {
        background-color: v.$searchColor !important;
        border-radius: v.$borderRadiusMini;
        > .start-marker,
        .end-marker {
            & > .marker-icon,
            & > .marker {
                border-color: var(--gray100) !important;
            }
        }
    }

    @keyframes wordSearchActivatedAnimation {
        0% {
            transform: scale(1);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
    &.currentHighlight {
        position: relative;
        background-color: transparent !important;
        &:after {
            top: 0;
            left: 0;
            height: calc(100%);
            width: calc(100%);
            position: absolute;
            content: '';
            background-color: v.$textSelected;
            border-radius: v.$borderRadiusMini;
            mix-blend-mode: multiply;
            animation: wordSearchActivatedAnimation 0.2s;
        }
    }

    &.selected > .word-real {
        background: v.$textSelected !important;
    }

    &.playing {
        > .word-real {
            background-color: var(--gray950) !important;
            color: white;
        }
    }
}

.word-real {
    white-space: break-spaces;
    padding-top: 5px;
    padding-bottom: 5.5px;
}

.end-marker {
    padding: 0 0.125rem;
    .marker {
        width: 7px;
        height: 18px;
        border-radius: v.$borderRadiusMini;
        background-color: var(--gray300);
    }
}

.start-marker,
.end-marker {
    display: inline-block;
    height: 24px;
    &.subtitle-break {
        stroke-width: 1px;
        cursor: move;
        padding-right: 0.25rem;
    }

    &:not(.subtitle-break) > .marker-icon,
    > .marker {
        border-radius: v.$borderRadiusMini + 1px;
        border: 1px solid #fff;
        cursor: pointer;
    }
    > .marker-icon,
    > .marker {
        display: inline-flex;
        transform: translateY(3px);
    }
    > .marker-icon {
        color: var(--gray300);
    }

    > .marker {
        height: 18px;
        background-color: var(--gray300);
    }

    &.hover {
        &.subtitle-break > .marker-icon {
            stroke: v.$textSelected;
            color: var(--gray800);
        }
        &:not(.subtitle-break) > .marker-icon {
            border-color: v.$textSelected !important;
            color: var(--gray800);
        }
        > .marker {
            border-color: v.$textSelected !important;
            background-color: var(--gray800);
        }
    }

    &.active {
        > .marker-icon {
            color: var(--gray800);
        }
        > .marker {
            background-color: var(--gray800);
        }
    }

    &.edit {
        > .marker-icon {
            color: var(--gray950) !important;
        }
        > .marker {
            background-color: var(--gray950);
        }
    }

    &.drag {
        &.subtitle-break > .marker-icon {
            stroke: rgb(193, 198, 224);
            color: var(--gray950);
        }
        &:not(.subtitle-break) > .marker-icon {
            border-color: rgb(193, 198, 224);
            color: var(--gray950);
        }
        > .marker {
            border-color: rgb(193, 198, 224);
            background-color: var(--gray950);
        }
    }
}

.custom-text-preview .end-marker.marker-template .marker-icon {
    border-radius: 0;
}

.translation-demo-overlay {
    margin-top: 128px;

    &__dialog {
        @include v.shadowFloating;

        &__text {
            color: var(--gray950);
        }
    }
}
