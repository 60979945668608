@use 'variables' as v;

.typ-card {
    padding: 2rem;
    background: white;
    border-radius: 0.5rem;
}

.typ-wrapper {
    padding: 2rem;
    background: white;
    border-radius: 1rem;
    @include v.shadowFloating;
}

.typ-card__label {
    margin: 0;
}
