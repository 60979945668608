@use 'variables' as v;

.badge {
    position: relative;
    padding: 0 v.$paddingSmall;
    border-radius: 10px;
    &:after {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: v.$gradientUpgrade;
        background-size: 100%;
        background-clip: text;
    }
}
.badge-starter {
    &-transparent {
        background: var(--fullWhite);
        color: transparent;
    }
    &-solid {
        background: v.$gradientUpgrade;
        color: var(--fullWhite);
    }
}
