.limitation-reached-container {
    max-width: 520px;
    text-align: center;

    & h2 {
        margin-top: 0;
    }

    & .upgrade-button {
        margin-left: 8px;
        margin-right: 8px;
    }
}
